<template>
  <store-transaction-list />
</template>

<script>
import StoreTransactionList from '@/components/pages/sales/StoreTransactionList'

export default {
  components: {
    StoreTransactionList
  }
}
</script>
